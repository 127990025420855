<template>
  <div class="about">
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-md-6">
          <p>
            ¡Hola! Soy Facundo, un apasionado de la fotografía con un enfoque
            especial en capturar momentos auténticos y emociones genuinas. Mi
            objetivo es contar historias a través de imágenes, capturando la
            belleza de los momentos fugaces y eternizando recuerdos.
          </p>
          <p>
            Además de mi pasión por la fotografía, me dedico a proporcionar una
            experiencia excepcional a mis clientes. Me esfuerzo por crear un
            ambiente relajado y divertido durante las sesiones, para que todos
            se sientan cómodos y puedan ser ellos mismos frente a la cámara. La
            satisfacción de mis clientes es mi prioridad y siempre me esfuerzo
            por superar sus expectativas.
          </p>
          <p>
            Si estás buscando un fotógrafo creativo y comprometido, estaré
            encantado de trabajar contigo. Permíteme capturar los momentos más
            importantes de tu vida y transformarlos en recuerdos duraderos.
            ¡Espero tener la oportunidad de crear algo hermoso juntos!
          </p>
        </div>
        <div class="col-md-6">
          <img
            src="/img/_DSC0223.jpg"
            alt="Nosotros"
            class="img-fluid"
            style="max-width: 500px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
